<template>

  <v-bottom-navigation fixed  v-model="value" grow active-class='v-btn--active' style="">
    <v-btn value="recent" style="height: 100%;" >
      <router-link to="/" class="rlink">
        <v-icon size="30" color="blue-grey darken-4">fas fa-bell</v-icon><br/>
        <span> خانه</span>
      </router-link>
    </v-btn>
    <v-btn value="recent" style="height: 100%;" >
      <router-link to="/gamelist" class="rlink">
        <v-icon size="30" color="blue-grey darken-4">fas fa-trophy</v-icon><br/>
        <span>مسابقات</span>
      </router-link>
    </v-btn>
    <v-btn value="recent" style="height: 100%;">
      <router-link  to="/admin" class="rlink">
        <!--<v-badge color="amber" :content="cart.length + 0" left v-if="cart.length"></v-badge>-->
        <v-icon size="30" color="blue-grey darken-4">fas fa-list-alt</v-icon><br/>
        <span>مدیریت</span>
      </router-link>
    </v-btn>
    <v-btn value="recent" style="height: 100%;" >
      <router-link to="/profile" class="rlink">
        <v-icon size="30" color="blue-grey darken-4">fas fa-money-bill-alt</v-icon><br/>
        <span>هدیه به سایت</span>
      </router-link>
    </v-btn>
    <v-btn value="recent" style="height: 100%;" >
      <router-link to="/about" class="rlink">
        <v-icon size="30" color="blue-grey darken-4">fas fa-phone-volume</v-icon><br/>
        <span>مدیر</span>
      </router-link>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import {mapGetters} from 'vuex';
import { CurvedBottomNavigation } from "bottom-navigation-vue";
import "../assets/dist/style.css";
import "../assets/fontawsome/css/all.css";


export default {
  name: 'appFooter',
  components: {
    mapGetters,
    CurvedBottomNavigation
  },
  data () {
    return {
      value: 'recent',
      selected: 5,
      options: [
        {
          id: 1,
          icon: "fas fa-money-bill-alt",
          title: "هدیه به سایت",
          childs: [{ id: 401, icon: "fas fa-money-bill-alt", title: "هدیه " ,path: { name: "profile"},}],
        },
        { id: 2, icon: "fas fa-phone-volume", title: "ارتباط با مدیر",
          childs: [{ id: 201, icon: "fas fa-phone-volume", title: "مدیر" ,path: { name: "about"},}],
        },
        { id: 3, icon: "fas fa-list-alt", title: "مدیریت",
          childs: [{ id: 301, icon: "fas fa-list-alt", title: "مدیریت" ,path: { name: "admin"},}],
        },
        { id: 4, icon: "fas fa-trophy", title: "مسابقات",
          childs: [{ id: 401, icon: "fas fa-trophy", title: "مسابقات" ,path: { name: "gamelist"}}],

        },
        { id: 5, icon: "fas fa-bell", title: "اطلاعیه و اخبار", /*badge: 15 ,*/
        path: { name: "Home"}},
      ],
    }
  },

  computed:{
    ...mapGetters(['cart'])
  },
  methods:{
  },

}
</script>

<style>
.rlink{
  text-decoration: none;
}
.rlink span{
  color: #263238
}
.v-btn--active{
  background: green;
}
</style>
